import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/smartAgriculture',
    },
    {
        path: '/smartAgriculture',
        name: 'SmartAgriculture',
        component: () => import('@/views/smartAgriculture.vue'),
        meta: {
            title: '智慧农业数据大屏'
        }
    },
    {
        path: '/smartMap',
        name: 'SmartMap',
        component: () => import('@/views/smartMap.vue'),
        meta: {
            title: '智慧农场地图'
        }
    },
    {
        path: '/anotherMap',
        name: 'AnotherMap',
        component: () => import('@/views/anotherMap.vue'),
        meta: {
            title: '智慧农场地图'
        }
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
