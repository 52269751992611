import request from "./request.js";
// 接口地址
const apiList = {
	getHome(params) {
		return request.request({
			url: `apps/ssp/home`,
			method: 'GET',
			params: params
		})
	},
	getWeather(params) {
		return request.request({
			url: `http://www.nmc.cn/rest/weather?stationid=58606`,
			method: 'GET',
			params: params
		})
	},
	getScenicSpot(params) {
		return request.request({
			url: `apps/ssp/getScenicSpot`,
			method: 'GET',
			params: params
		})
	},
	getChildrensTitle(params) {
		return request.request({
			url: `apps/ssp/getChildrensTitle`,
			method: 'GET',
			params: params
		})
	},
	getChildrens(params) {
		return request.request({
			url: `apps/ssp/getChildrens`,
			method: 'GET',
			params: params
		})
	},
	getPageList(params) {
		return request.request({
			url: `/apps/ssp/getPageList`,
			method: 'GET',
			params: params
		})
	},

}

export default apiList

