import Vue from 'vue'
import App from './App.vue'
import router from './router'
import dataV from '@jiaminghi/data-view'
import 'lib-flexible/flexible'
import * as echarts from 'echarts'
import moment from "moment"
import { VueJsonp } from 'vue-jsonp'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import api from './api/index'

// import "./assets/css/index.scss";
Vue.use(preview)
Vue.prototype.$moment = moment;
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts;
Vue.prototype.$api=api;
Vue.use(dataV)
Vue.use(VueJsonp);

Vue.use(ElementUI);
new Vue({
  router,
  el: '#app',
  render: h => h(App),
}).$mount('#app')

//定义图片的过滤器
Vue.filter('imgOnline', function(path) {
  if (path) {
    path = 'https://dxpg.jdoffices.cn/apis/file/get?id=' + path + '&appid=supply';

  }
  return path;
})
