<template>
    <scale-box>
        <div id="app">
            <router-view/>
        </div>
    </scale-box>
</template>

<script>
    import ScaleBox from '@/components/ScaleBox/ScaleBox'
    export default {
        name: 'App',
        components: {
            ScaleBox
        }
    }
</script>
<style lang="scss">
    @import "./assets/css/index.scss";
</style>

<style scoped lang="scss">
    #app {
        width: 100%;
        height: 100%;
    }

</style>
